import { type VariantProps, cva } from 'class-variance-authority';

export { default as Button } from './Button.vue';

/**
 * マウスオーバー時に、子要素のstyleを変える方法
 * 親要素にgroupクラスをつけて、子要素にgroup-hover:クラス名をつける
 * @see https://zenn.dev/ilove/articles/f501e24a0a12a8
 */
// MEMO: shadowは結構パターンがあるので、使う側で各自定義する想定
export const buttonVariants = cva(
  'relative flex items-center justify-center gap-x-1 whitespace-nowrap p-1 text-base font-bold transition-all disabled:pointer-events-none disabled:opacity-50 md:hover:opacity-75 md:focus-visible:opacity-75 md:focus-visible:outline-blue-500',
  {
    variants: {
      variant: {
        default: 'rounded-button-sp md:rounded-button',
        sharp: 'rounded-none',
        fullRounded: 'rounded-full',
      },
      colors: {
        default: 'bg-primary text-black',
        blue: 'bg-blue-400 text-white',
        transparent: 'bg-transparent text-white',
        white: 'bg-white text-black',
        orange: 'bg-orange text-white',
      },
    },
    defaultVariants: {
      variant: 'default',
      colors: 'default',
    },
  },
);

export const frameVariants = cva(
  'absolute-center h-[87%] w-[97%] rounded-[inherit] border-[3px] transition-all',
  {
    variants: {
      frameColors: {
        default: 'border-black',
        white: 'border-white',
        blue: 'border-blue-400',
        none: 'hidden',
      },
    },
    defaultVariants: {
      frameColors: 'default',
    },
  },
);

export type ButtonVariants = VariantProps<typeof buttonVariants>;
export type FrameVariants = VariantProps<typeof frameVariants>;
