import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src968215633/src/segaxd_minion_campaign/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src968215633/src/segaxd_minion_campaign/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src968215633/src/segaxd_minion_campaign/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/codebuild/output/src968215633/src/segaxd_minion_campaign/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import amplify_apis_client_O6WT4PP27r from "/codebuild/output/src968215633/src/segaxd_minion_campaign/src/plugins/amplify-apis.client.ts";
import payload_client_yVLowv6hDl from "/codebuild/output/src968215633/src/segaxd_minion_campaign/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src968215633/src/segaxd_minion_campaign/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src968215633/src/segaxd_minion_campaign/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src968215633/src/segaxd_minion_campaign/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_dw2T9lEw4h from "/codebuild/output/src968215633/src/segaxd_minion_campaign/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaults_aRl2YLnTcb from "/codebuild/output/src968215633/src/segaxd_minion_campaign/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
import siteConfig_izaWKZ8rEu from "/codebuild/output/src968215633/src/segaxd_minion_campaign/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "/codebuild/output/src968215633/src/segaxd_minion_campaign/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_client_LcKgStRyi6 from "/codebuild/output/src968215633/src/segaxd_minion_campaign/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src968215633/src/segaxd_minion_campaign/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  amplify_apis_client_O6WT4PP27r,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  titles_dw2T9lEw4h,
  defaults_aRl2YLnTcb,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  plugin_client_LcKgStRyi6,
  chunk_reload_client_UciE0i6zes
]