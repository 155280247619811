<template>
  <NuxtErrorBoundary @error="onError">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </NuxtErrorBoundary>
</template>

<script setup lang="ts">
const {
  public: { baseUrl },
} = useRuntimeConfig();

useSeoMeta({
  ogImage: `${baseUrl}/images/ogp.webp`,
});

const onError = (error: any) => {
  console.error(error);
  showError(error);
};
</script>
