<script setup lang="ts">
import { Primitive, type PrimitiveProps } from 'radix-vue';
import type { HTMLAttributes } from 'vue';
import {
  type ButtonVariants,
  buttonVariants,
  type FrameVariants,
  frameVariants,
} from '.';
import { cn } from '~/utils/index';

interface Props extends PrimitiveProps {
  variant?: ButtonVariants['variant'];
  colors?: ButtonVariants['colors'];
  class?: HTMLAttributes['class'];
  frameColors?: FrameVariants['frameColors'];
}

const props = withDefaults(defineProps<Props>(), {
  as: 'button',
});
</script>

<template>
  <Primitive
    :as="as"
    :as-child="asChild"
    :class="cn(buttonVariants({ variant, colors }), props.class)"
  >
    <slot />
    <div :class="cn(frameVariants({ frameColors }))" />
  </Primitive>
</template>
