import { default as indexYTrCPl9pUFMeta } from "/codebuild/output/src968215633/src/segaxd_minion_campaign/src/pages/apply/index.vue?macro=true";
import { default as index1FZsiWufhqMeta } from "/codebuild/output/src968215633/src/segaxd_minion_campaign/src/pages/index.vue?macro=true";
import { default as indexjUJu4pTpQrMeta } from "/codebuild/output/src968215633/src/segaxd_minion_campaign/src/pages/missions/01-standard/index.vue?macro=true";
import { default as indexvpuYFDWLxKMeta } from "/codebuild/output/src968215633/src/segaxd_minion_campaign/src/pages/missions/02-challenge/index.vue?macro=true";
import { default as indexGc0mMVo4E8Meta } from "/codebuild/output/src968215633/src/segaxd_minion_campaign/src/pages/missions/03-extreme/index.vue?macro=true";
import { default as indexU2OrWLsjfEMeta } from "/codebuild/output/src968215633/src/segaxd_minion_campaign/src/pages/missions/04-secret/index.vue?macro=true";
import { default as indexR8Bw4OJ5QlMeta } from "/codebuild/output/src968215633/src/segaxd_minion_campaign/src/pages/missions/05-onsite/index.vue?macro=true";
import { default as index6XIXSrKz1XMeta } from "/codebuild/output/src968215633/src/segaxd_minion_campaign/src/pages/missions/index.vue?macro=true";
export default [
  {
    name: "apply",
    path: "/apply",
    meta: indexYTrCPl9pUFMeta || {},
    component: () => import("/codebuild/output/src968215633/src/segaxd_minion_campaign/src/pages/apply/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: index1FZsiWufhqMeta || {},
    component: () => import("/codebuild/output/src968215633/src/segaxd_minion_campaign/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "missions-01-standard",
    path: "/missions/01-standard",
    component: () => import("/codebuild/output/src968215633/src/segaxd_minion_campaign/src/pages/missions/01-standard/index.vue").then(m => m.default || m)
  },
  {
    name: "missions-02-challenge",
    path: "/missions/02-challenge",
    component: () => import("/codebuild/output/src968215633/src/segaxd_minion_campaign/src/pages/missions/02-challenge/index.vue").then(m => m.default || m)
  },
  {
    name: "missions-03-extreme",
    path: "/missions/03-extreme",
    component: () => import("/codebuild/output/src968215633/src/segaxd_minion_campaign/src/pages/missions/03-extreme/index.vue").then(m => m.default || m)
  },
  {
    name: "missions-04-secret",
    path: "/missions/04-secret",
    component: () => import("/codebuild/output/src968215633/src/segaxd_minion_campaign/src/pages/missions/04-secret/index.vue").then(m => m.default || m)
  },
  {
    name: "missions-05-onsite",
    path: "/missions/05-onsite",
    component: () => import("/codebuild/output/src968215633/src/segaxd_minion_campaign/src/pages/missions/05-onsite/index.vue").then(m => m.default || m)
  },
  {
    name: "missions",
    path: "/missions",
    component: () => import("/codebuild/output/src968215633/src/segaxd_minion_campaign/src/pages/missions/index.vue").then(m => m.default || m)
  }
]