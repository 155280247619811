<template>
  <div>
    <div class="container p-10 text-center">
      <h1 class="error__headline text-center font-bold">
        エラーが発生しました
      </h1>
      <p class="error__text">
        エラーが発生しましたので、「TOPに戻る」からアクセスをお願いいたします。
      </p>
      <Button
        class="error__button mx-auto flex"
        @click="clearError({ redirect: '/' })"
        >TOPに戻る</Button
      >
    </div>
  </div>
</template>

<style scoped lang="scss">
.error__headline {
  font-size: calcMinSp(40);
  margin-bottom: calcMinSp(50);
}
.error__text {
  font-size: calcMinSp(30);
  margin-bottom: calcMinSp(40);
}
.error__button {
  width: calcMinSp(450);
  height: calcMinSp(80);
  font-size: calcMinSp(25);
}
</style>
