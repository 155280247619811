// @see https://docs.amplify.aws/react/build-a-backend/data/connect-from-server-runtime/nuxtjs-server-runtime/#step-3---set-up-amplify-for-api-routes

import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/data';
import outputs from '~/..//amplify_outputs.json';
import type { Schema } from '~/../amplify/data/resource';

// configure the Amplify client library
if (import.meta.client) {
  Amplify.configure(outputs, { ssr: true });
}

// generate your data client using the Schema from your backend
const client = generateClient<Schema>();

export default defineNuxtPlugin({
  name: 'AmplifyAPIs',
  enforce: 'pre',
  setup() {
    return {
      provide: {
        // You can call the API by via the composable `useNuxtApp()`.
        Amplify: {
          GraphQL: {
            client,
          },
        },
      },
    };
  },
});
